<template>
  <div class="input-wrapper">
    <input
      :id="fieldId"
      v-model="value"
      :autocomplete="autocomplete"
      :class="{
        'input-base--error': error,
        'input-base--combobox': combobox,
        'input-base--disabled': disabled,
      }"
      :disabled="disabled"
      :placeholder="placeholder"
      :type="computedType"
      class="input-base"
    />

    <icon-eye
      v-if="isPasswordField"
      class="password__icon"
      @click="handlePasswordIconClick"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from "#imports";
import IconEye from "~/modules/shared/components/icons/IconEye.vue";

const props = withDefaults(
  defineProps<{
    autocomplete?: string;
    combobox?: boolean;
    disabled?: boolean;
    error?: boolean;
    fieldId: string;
    placeholder?: string;
    type?: "text" | "number" | "email" | "tel" | "password";
  }>(),
  {
    autocomplete: undefined,
    combobox: false,
    disabled: false,
    error: false,
    placeholder: "",
    type: "text",
  },
);

const showAsText = ref(false);
const value = defineModel<string | number | null | undefined>();

const computedType = computed(() => {
  if (showAsText.value) return "text";

  return props.type;
});
const isPasswordField = computed(() => props.type === "password");

const handlePasswordIconClick = () => (showAsText.value = !showAsText.value);
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixins";

.input-wrapper {
  @apply relative w-full flex items-center;

  .password__icon {
    @apply absolute right-8 cursor-pointer;

    &:hover {
      :deep {
        path {
          fill: theme("colors.primary-450");
        }
      }
    }
  }
}

.input-base {
  @include inputBase;
  appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    @apply appearance-none m-0;
  }

  &--combobox {
    @apply w-auto flex-1;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &--disabled {
    @apply opacity-50 cursor-not-allowed;
  }
}
</style>
